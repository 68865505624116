import Vue from 'vue'
import App from './App.vue'
import router from './router'
import user from "./util/user";
import util from "./util/util";
import http from './util/http';

Vue.config.productionTip = false

Vue.prototype.$http = http;
Vue.prototype.$user = user;
Vue.prototype.$util = util;

Vue.config.errorHandler = function (err, vm, info) {
	const {
		message, // 异常信息
		name, // 异常名称
		script, // 异常脚本url
		line, // 异常行号
		column, // 异常列号
		stack // 异常堆栈信息
	} = err;
	http({
		url: '/vip/submitError',
		data: {
			error: JSON.stringify({
				message, // 异常信息
				name, // 异常名称
				script, // 异常脚本url
				line, // 异常行号
				column, // 异常列号
				stack,
			}),
		},
	});
};

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
