<template>
	<div>
		<div v-if="!unionId" class="load-mask"></div>

		<div class="content-container">
			<div class="content-card padding">
				<div class="title-wrapper">
					<div class="line"></div>
					<div class="title">选择套餐</div>
				</div>
				<div class="price-wrapper">
					<div class="price-card" :class="{active: selectIndex === index}" v-for="(item, index) in vipList" :key="item.state" @click.stop="onVipItemClick(index)">
						<div class="vip-name">{{item.name}}</div>
						<div class="price"><span class="price-icon">￥</span>{{item.price}}</div>
						<div class="price-sub-text">{{item.desc}}</div>
						<div class="price-badge" :style="{'background-color': item.badgeColor}" v-if="item.badge">
							{{ item.badge }}
						</div>
					</div>
				</div>

				<div class="title-wrapper">
					<div class="line"></div>
					<div class="title">好友ID</div>
				</div>
				<div v-if="!targetUser.number_id" class="input-wrapper">
					<input class="input" type="number" maxlength="14" placeholder="请输入好友 ID" v-model="searchNumberId" />
					<div class="confirm-btn" @click.stop="onSearch">确定</div>
				</div>
				<div v-if="targetUser.number_id" class="target-user">
					<img class="target-user-avatar" mode="aspectFill" :src="targetUser.avatar">
					<div class="info-wrapper">
						<div class="nickname">{{targetUser.nickname}}</div>
						<div class="number-id">ID：{{targetUser.number_id}}</div>
					</div>
					<div class="reset-btn" @click.stop="reset">重新输入</div>
				</div>
				<div class="vip-tip-text">赠送会员即同意<span class="link" @click.stop="toServiceAgreement">《朵朵增值服务协议》</span></div>
				<div class="button primary" :class="{disable: !targetUser.number_id}" @click.stop="buyWrapper">赠送好友</div>
			</div>
		</div>

		<loading ref="loading"></loading>

		<modal ref="modal"></modal>
	</div>
</template>

<script>
import Loading from "../components/Loading";
import Modal from "../components/Modal";

export default {
	name: "GiveAway",
	components: { Modal, Loading },
	data() {
		return {
			vipList: [],
			selectIndex: 0,
			targetUser: {},
			userData: {},

			unionId: '',
			openid: '',

			orderNumber: '',

			searchNumberId: '',

			btnDisable: false,
		}
	},
	async mounted() {
		let code = this.$route.query.code;
		this.unionId = this.$user.getLocalUnionId();
		this.openid = this.$user.getLocalOpenid();
		if (!code && (!this.unionId || !this.openid)) {
			this.$user.toAuthPage();
			return;
		}

		let userData = null;
		if (code && !this.unionId) {
			let result = await this.$http({
				url: '/vip/getUserDataForOfficial',
				data: {
					code,
				}
			});
			if (!result || !result.user) {
				this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
				return;
			}
			this.unionId = result.officialUserdata.unionid;
			this.openid = result.officialUserdata.openid;
			this.$user.setLocalOpenid(result.officialUserdata.openid);
			this.$user.setLocalUnionId(result.officialUserdata.unionid);
			userData = result.user;
		}

		if (!userData) {
			userData = (await this.$http({
				url: '/vip/getUserDataForOfficialWithUnionId',
				data: {
					union_id: this.unionId,
				},
			})).user;
		}

		if (!userData) {
			this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
			return;
		}

		this.userData = userData;

		let vipListResult = await this.$http({
			url: '/vip/getVipStateList',
			data: {
				platform: 'miniprogram',
			},
		});
		vipListResult.list.forEach(item => {
			item.price = item.price / 100;
			item.priceText = item.price.toFixed(2);
			if (item.originalPrice) {
				item.originalPrice = item.originalPrice / 100;
				item.originalPriceText = item.originalPrice.toFixed(2);
				item.discount = (item.price / item.originalPrice * 10).toFixed(1);
			}
		})
		this.vipList = vipListResult.list;
		this.selectIndex = vipListResult.defaultSelect;


		// this.$util.setJSSDKConfig(['chooseWXPay']);
	},
	methods: {
		onVipItemClick(index) {
			this.selectIndex = index;
		},

		onSearch() {
			if (!this.searchNumberId) {
				this.$refs.modal.show('请输入好友 ID');
				return;
			}

			this.$http({
				url: '/vip/getUserByNumberId',
				data: {
					union_id: this.unionId,
					number_id: this.searchNumberId,
				},
			}).then(res => {
				this.targetUser = res;
			}).catch(err => {
				this.$refs.modal.show(err);
			});
		},

		reset() {
			this.targetUser = {};
			this.searchNumberId = '';
		},

		async buyWrapper() {
			if (this.btnDisable) {
				return;
			}
			this.btnDisable = true;
			await this.buy();
			this.btnDisable = false;
		},

		async buy() {
			if (!this.targetUser.number_id) {
				return;
			}

			let selectVip = this.vipList[this.selectIndex];
			if (!selectVip) {
				this.$refs.modal.show('请选择会员时长');
				return;
			}

			this.$refs.loading.show();

			let res = await this.$http({
				url: '/vip/createOrder',
				data: {
					union_id: this.unionId,
					official_openid: this.openid,
					from: 'official',
					type: 2,
					level: selectVip.state,
					receive_number_id: this.targetUser.number_id,
				},
			}).catch(err => {
				this.$refs.modal.show(err);
				return null;
			});
			if (!res) {
				this.$refs.loading.hide();
				return;
			}

			this.orderNumber = res.orderNumber;
			if (res.state === 1) {
				this.$refs.loading.hide();
				return this.showSuccessModal();
			}

			let payIdRes = await this.$http({
				url: '/vip/getPayId',
				data: {
					union_id: this.unionId,
					from: 'official',
					order_number: this.orderNumber,
				},
			}).catch(err => {
				this.$refs.modal.show(err);
				return null;
			});
			if (!payIdRes) {
				this.$refs.loading.hide();
				return;
			}

			wx.chooseWXPay({
				timestamp: payIdRes.timeStamp,
				nonceStr: payIdRes.nonceStr,
				package: payIdRes.package,
				signType: payIdRes.signType,
				paySign: payIdRes.paySign,
				success: () => {
					this.startCheckOrderState();
				},
				cancel: () => {
					this.$refs.loading.hide();
				},
				fail: () => {
					this.$refs.loading.hide();
				},
			});
		},

		startCheckOrderState() {
			if (!this.orderNumber) {
				this.$refs.loading.hide();
				return;
			}

			this.$refs.loading.show('结果查询中');

			this.timeId = setInterval(() => {
				this.$http({
					url: '/vip/checkPaySuccess',
					data: {
						union_id: this.unionId,
						from: 'official',
						order_number: this.orderNumber,
					},
				}).then(res => {
					if (res.pay_success) {
						clearInterval(this.timeId);
						this.$refs.loading.hide();
						this.showSuccessModal();
					}
				});
			}, 1000);
		},

		async showSuccessModal() {
			let selectVip = this.vipList[this.selectIndex];
			this.$refs.loading.hide();
			await this.$refs.modal.show(`开通成功！受赠人的会员身份已生效${selectVip.state > 1 ? '或延长' : ''}，可尽享会员专属权益`);
			location.reload();
		},

		toServiceAgreement() {
			window.open('https://static.duoduo.link/Common_Service_Agreement.html');
		},
	}
}
</script>

<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.content-container {
	background-color: #F7F7F7;
	padding: 0 pxToRem(30);
	box-sizing: border-box;
	overflow: auto;
	min-height: 100vh;
}

.content-card {
	background-color: white;
	border-radius: pxToRem(20);
	box-shadow: 0 pxToRem(4) pxToRem(12) 0 rgba(0, 0, 0, 0.08);
	margin: pxToRem(20) auto;
}

.content-card.padding {
	padding: pxToRem(40) pxToRem(30);
	box-sizing: border-box;
}

.price-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: pxToRem(40);
}

.price-card {
	position: relative;
	width: pxToRem(198);
	height: pxToRem(270);
	padding-top: pxToRem(66);
	text-align: center;
	background-color: #FFFFFF;
	border-radius: pxToRem(8);
	border: pxToRem(2) solid #BBBBBB;
	box-sizing: border-box;
}

.price-card.active {
	background-color: #F6EBE3;
	border-color: #DDB190;
}

.vip-name {
	font-size: pxToRem(28);
	color: #333333;
	margin-bottom: pxToRem(12);
}

.price {
	font-size: pxToRem(56);
	color: #FA5051;
	font-family: 'DINAlternate-Bold';
	margin-bottom: pxToRem(4);
}

.price-icon {
	font-size: pxToRem(24);
}

.price-sub-text {
	font-size: pxToRem(20);
	color: #777777;
}

.price-badge {
	position: absolute;
	top: pxToRem(-2);
	left: pxToRem(-2);
	padding: pxToRem(5) pxToRem(14);
	border-radius: pxToRem(8) 0 pxToRem(8) 0;
	font-size: pxToRem(22);
	color: white;
}

.title-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: pxToRem(20);
}

.line {
	width: pxToRem(8);
	height: pxToRem(30);
	background-color: #37414B;
	margin-right: pxToRem(16);
}

.title {
	font-size: pxToRem(28);
	line-height: pxToRem(42);
	color: #333333;
	font-weight: bold;
}

.input-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: pxToRem(60);
}

.input {
	flex: 1;
	height: pxToRem(82);
	line-height: pxToRem(82);
	border: pxToRem(2) solid #BBBBBB;
	box-sizing: border-box;
	border-radius: pxToRem(8);
	background-color: #F7F7F7;
	padding: 0 pxToRem(30);
	font-size: pxToRem(28);
	color: #333333;
}

.confirm-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: pxToRem(20);
	color: white;
	background-color: #37414B;
	width: pxToRem(136);
	height: pxToRem(82);
	border-radius: pxToRem(8);
	font-size: pxToRem(32);
}

.target-user {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #EEEEEE;
	border-radius: pxToRem(20);
	padding: pxToRem(30);
	box-sizing: border-box;
	margin-bottom: pxToRem(60);
}

.target-user-avatar {
	width: pxToRem(110);
	height: pxToRem(110);
	display: block;
	border-radius: 100%;
	margin-right: pxToRem(24);
}

.info-wrapper {
	flex: 1;
	font-size: 0;
}

.nickname {
	font-size: pxToRem(32);
	line-height: pxToRem(48);
	color: #333333;
	font-weight: bold;
	margin-bottom: pxToRem(12);
}

.number-id {
	display: inline;
	padding: pxToRem(5) pxToRem(16);
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: pxToRem(40);
	font-size: pxToRem(20);
	color: #D9D9D9;
}

.reset-btn {
	font-size: pxToRem(24);
	font-weight: bold;
	color: #576B94;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: pxToRem(88);
	border-radius: pxToRem(80);
	font-size: pxToRem(30);
	font-weight: bold;
}

.button.primary {
	background: linear-gradient(135deg, #F7E9D2 0%, #DDB190 100%);
	color: #37414B;
}

.button.disable {
	background: #BBBBBB;
	color: #37414B;
}

.vip-tip-text {
	font-size: pxToRem(21);
	color: #999999;
	margin-bottom: pxToRem(40);
	text-align: center;
}

.link {
	color: #576B94;
	text-decoration: underline;
}
</style>
