<template>
	<div>
		<div class="mask" :class="{show: maskShow}" v-show="display">
			<div class="center-modal" :class="{show: modalShow}">
				<div class="text">{{text}}</div>
				<div class="button" @click.stop="hide">好的</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Modal",
	data() {
		return {
			display: false,
			text: '加载中',

			maskShow: false,
			modalShow: false,
		}
	},
	methods: {
		Ooops() {
		},
		async show(text) {
			this.text = text;
			this.display = true;

			setTimeout(() => {
				this.maskShow = true;
			}, 20);
			setTimeout(() => {
				this.modalShow = true;
			}, 160);

			return new Promise(resolve => {
				let timeId = setInterval(() => {
					if (!this.display) {
						clearInterval(timeId);
						resolve();
					}
				}, 100);
			})
		},
		hide() {
			this.modalShow = false;
			setTimeout(() => {
				this.maskShow = false;
			}, 160);
			setTimeout(() => {
				this.display = false;
			}, 180);
		},
	}
}
</script>

<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.mask {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	opacity: 0;
	transition: opacity .2s linear;

	&.show {
		opacity: 1;
	}
}

.center-modal {
	width: pxToRem(600);
	background-color: white;
	border-radius: pxToRem(20);
	transform: scale(0);
	opacity: 0.2;
	transition: all .15s ease-in-out;
	overflow: hidden;

	&.show {
		transform: scale(1);
		opacity: 1;
	}
}

.text {
	padding: pxToRem(70) pxToRem(60);
	font-size: pxToRem(32);
	font-weight: bold;
	text-align: center;
}

.button {
	padding: pxToRem(30);
	font-size: pxToRem(30);
	font-weight: bold;
	text-align: center;
	border-top: 1px #E5E5E5 solid;

	&:active {
		background-color: #E5E5E5;
	}
}
</style>
