<template>
	<div class="hey-button" :class="[backClass]">
		<div
			v-if="content"
			class="hey-button-content"
		>{{content}}</div>
		<div
			v-if="desc"
			class="hey-button-desc"
		>{{desc}}</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'HeyButton',
	props: {
		back: {
			type: String,
			default: 'purple',
		},
		content: {
			type: String,
			default: '',
		},
		desc: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'default',
		},
	},
	computed: {
		backClass() {
			return `${this.back}-back ${this.size}`;
		}
	},
};
</script>

<style lang="scss" src="../style/heyCommon.scss" />
<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.hey-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: pxToRem(30);
	padding: pxToRem(20) pxToRem(50);
	min-height: pxToRem(84);
	box-sizing: border-box;
	color: white;
}
.hey-button.large {
	min-height: pxToRem(104);
}
.hey-button-content {
	font-size: pxToRem(30);
	line-height: 1.5;
	font-weight: bold;
}
.hey-button-desc {
	font-size: pxToRem(20);
	line-height: 1.5;
}
.hover-item-opacity {
	opacity: .9;
}
</style>
