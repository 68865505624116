import { render, staticRenderFns } from "./BuyHeidou.vue?vue&type=template&id=2ea9d18e&scoped=true"
import script from "./BuyHeidou.vue?vue&type=script&lang=js"
export * from "./BuyHeidou.vue?vue&type=script&lang=js"
import style0 from "../style/heyCommon.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "../style/heyCard.scss?vue&type=style&index=1&prod&lang=scss&external"
import style2 from "./BuyHeidou.vue?vue&type=style&index=2&id=2ea9d18e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea9d18e",
  null
  
)

export default component.exports