import Vue from 'vue'
import Router from 'vue-router'
import Index from '../pages/Index'
import GiveAway from "../pages/GiveAway";
import BuyRecord from "../pages/BuyRecord";
import BuyHeidou from '../pages/BuyHeidou';
import HeidouHistory from '../pages/HeidouHistory';

Vue.use(Router);
const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Index',
			meta: {title: '首页', desc: '这是首页'},
			component: Index,
		},
		{
			path: '/GiveAway',
			name: 'GiveAway',
			meta: {title: '首页', desc: '这是首页'},
			component: GiveAway,
		},
		{
			path: '/BuyRecord',
			name: 'BuyRecord',
			meta: {title: '首页', desc: '这是首页'},
			component: BuyRecord,
		},
		{
			path: '/BuyHeidou',
			name: 'BuyHeidou',
			meta: {title: '首页', desc: '这是首页'},
			component: BuyHeidou,
		},
		{
			path: '/HeidouHistory',
			name: 'HeidouHistory',
			meta: {title: '首页', desc: '这是首页'},
			component: HeidouHistory,
		},
	]
});

export default router;
