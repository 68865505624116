import axios from 'axios'

let http = axios.create({
	baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
	timeout: 60000,
	method: 'POST',
	post: {
		'content-type': 'application/json'
	},
	// withCredentials: true,
	validateStatus: () => true,
});

http.interceptors.response.use(
	response => {
		if (response.data.status !== 0) {
			alert(response.data.msg);
			return Promise.reject(response.data.msg);
		}
		return response.data.result;
	},
	error => {
		alert(String(error));
		return Promise.reject(error);
	}
);

export default http;
