function formatNumber(n) {
	n = n.toString();
	return n[1] ? n : '0' + n;
}

export default {
	formatTime: function(date, format, dateDivisionString = '-') {
		date = new Date(date);
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();

		let hour = date.getHours();
		let minute = date.getMinutes();
		let second = date.getSeconds();

		if (format === "date") {
			return [year, month, day].map(formatNumber).join(dateDivisionString);
		} else if (format === "monthTime") {
			return [month, day].map(formatNumber).join(dateDivisionString) + ' ' + [hour, minute].map(formatNumber).join(':');
		} else {
			return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
		}
	},

	rootFontSize: function() {
		if (!window.innerWidth) return 37.5;
		if (window.innerWidth > 450) {
			return 37.5;
		} else {
			return window.innerWidth / 10;
		}
	},

	pxToRem: function(px) {
		return 10 * px / 750 + 'rem';
	},

	setJSSDKConfig: function(jsApiList, readyHandler) {
		fetch('https://ad.duoduo.link/Advertisement/getJSSDKConfig', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				url: window.location.href,
			}),
		}).then(res => {
			return res.text();
		}).then(res => {
			let config = JSON.parse(res).result;
			wx.config({
				debug: false,
				appId: config.appId,
				timestamp: config.timestamp,
				nonceStr: config.nonceStr,
				signature: config.signature,
				jsApiList: jsApiList,
			});
			if (readyHandler) {
				wx.ready(readyHandler);
			}
		})
	}
}
