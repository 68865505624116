<template>
	<div>
		<div class="mask" v-show="display" @touchstart.stop.prevent="Ooops">
			<div class="center-loading">
				<img class="loading-icon" src="../assets/loading.gif" />
				<div class="loading-text">{{text}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Loading",
	data() {
		return {
			display: false,
			text: '加载中',
		}
	},
	methods: {
		Ooops() {
		},
		show(text) {
			this.text = text || '加载中';
			this.display = true;
		},
		hide() {
			this.display = false;
		}
	}
}
</script>

<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.mask {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
}

.center-loading {
	width: pxToRem(250);
	height: pxToRem(250);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: pxToRem(20);
}

.loading-icon {
	width: pxToRem(60);
	height: pxToRem(60);
	display: block;
	margin: 0 auto;
}

.loading-text {
	font-size: pxToRem(28);
	color: white;
	text-align: center;
	margin-top: pxToRem(40);
}
</style>
