<template>
	<div>
		<div v-if="!unionId" class="load-mask">
			<div class="loading-text" style="padding-top: 40px; text-align: center">加载中...</div>
		</div>

		<div class="header-wrapper">
			<div class="header-card">
				<img class="header-avatar" mode="aspectFill" :src="userData.avatar" />
				<div class="info-wrapper">
					<div class="nickname-wrapper">
						<div class="nickname">{{ userData.nickname }}</div>
						<img class="vip-badge" mode="widthFix" v-if="userData.vip_state > 0" :src="userData.badge.vipBadgeImg" />
					</div>
					<div class="vip-text">{{ vipText }}</div>
					<div class="vip-text">剩余 Tokens：{{ userData.AITokenAmount || 0 }}k</div>
				</div>
				<div class="number-id">ID: {{ userData.number_id }}</div>
				<img class="premium-img" src="https://img.uboxs.net/ahUlXnfwqcrr1FoiVsAFhOC6Pux07PiF5lsWi9F0.png" />
			</div>
		</div>

		<div class="content-container">
			<div class="price-type">
				<div v-for="item in typeList" :key="item.value" @click.stop="onClickType(item)" data-item="{{item}}" class="price-type-item" :class="{ active: curType.value === item.value }">
					<span>{{ item.text }}</span>
				</div>
			</div>
			<div class="content-card padding" style="margin-top: 0; border-top-left-radius: 0; border-top-right-radius: 0">
				<div class="price-wrapper">
					<div class="price-card" :class="{ active: selectIndex === index }" v-for="(item, index) in vipList" :key="item.state" @click.stop="onVipItemClick(index)">
						<div class="vip-name" :style="item.price >= 100 && curType.value !== 'vip' ? 'font-size: .3233333333rem;' : ''">{{ item.name }}</div>
						<div class="price-text-wrapper">
							<div class="price"><span class="price-icon">￥</span>{{ item.price.toFixed(2) }}</div>
							<div class="price-sub-text">{{ item.desc }}</div>
						</div>
						<div class="price-badge" :style="{ 'background-color': item.badgeColor }" v-if="item.badge">
							{{ item.badge }}
						</div>
					</div>
				</div>
				<div class="vip-tip-text">
					开通会员即同意
					<span v-if="curType.value === 'mix' || curType.value === 'vip'" class="link" catch:tap="toServiceAgreement">《朵朵增值服务协议》</span>{{ curType.value === "mix" ? "和" : ""
					}}<span v-if="curType.value === 'mix' || curType.value === 'ai'" class="link" catch:tap="toServiceAgreement">《朵朵 AI 助手服务协议》</span>
					，如有问题，请查看
					<span class="link" @click.stop="toQuestion">常见问题</span>
				</div>
				<div class="button primary" @click.stop="buyWrapper">即刻成为朵朵会员</div>
				<div class="link-btn-wrapper">
					<div class="link-btn" @click.stop="toBuyRecord">赠送/购买记录</div>
					<div class="link-btn" @click.stop="toGiveAway">赠送好友</div>
				</div>
			</div>

			<div class="content-card">
				<div class="content-head">
					<div class="head-main-text">尊享会员权益</div>
				</div>
				<div class="vip-rights-wrapper">
					<div class="vip-rights" v-for="item in rights" :key="item.title" :style="`grid-column-start: span ${item.span ? item.span : 12};`">
						<img class="rights-icon" :src="item.icon" />
						<div class="rights-name">{{ item.title }}</div>
						<div class="rights-desc">{{ item.desc }}</div>
					</div>
				</div>
				<div class="more-text-wrapper" id="more">
					<img class="more-icon" src="https://img.uboxs.net/NL7yDJte6SMx4bY0HImv9Biq6AN4q3JIBbBB9K5a.png" />
					<div class="more-text">更多会员福利持续增加中…</div>
				</div>
			</div>

			<div class="content-card">
				<div class="content-head">
					<div class="head-main-text">常见问题</div>
					<div class="head-sub-text" @click.stop="openAllQuestion">展开所有问题</div>
				</div>
				<div class="vip-QA-wrapper">
					<div class="vip-QA" v-for="(item, index) in qa" :key="item.question">
						<div class="question" @click.stop="openQuestion(index)">
							<div class="question-text">{{ item.question }}</div>
							<img class="question-arrow" :class="{ open: item.open }" src="../assets/arrow_block_list.png" />
						</div>
						<div class="answer" v-if="item.open">{{ item.answer }}</div>
					</div>
				</div>
			</div>

			<div style="width: 1px; height: 140px"></div>
		</div>

		<div class="bottom-buy-container" v-if="vipList.length > 0">
			<div class="discount-badge" v-if="vipList[selectIndex].discount">限时 {{ vipList[selectIndex].discount }} 折特惠</div>
			<div class="bottom-padding">
				<div class="bottom-buy-wrapper">
					<div class="price-info">
						<div class="bottom-text-1">合计￥</div>
						<div class="bottom-price">{{ vipList[selectIndex].price.toFixed(2) }}</div>
						<div class="bottom-original-price" v-if="vipList[selectIndex].originalPrice">原价：{{ vipList[selectIndex].originalPrice.toFixed(2) }} 元</div>
					</div>
					<div class="bottom-button-wrapper">
						<div class="button primary" @click.stop="buyWrapper">开通{{ vipList[selectIndex].name }}会员</div>
					</div>
				</div>
			</div>
		</div>

		<loading ref="loading"></loading>

		<modal ref="modal"></modal>
	</div>
</template>

<script>
import Loading from "../components/Loading";
import Modal from "../components/Modal";
export default {
	name: "Index",
	components: { Modal, Loading },
	data() {
		return {
			curType: { text: "会员+AI 助手", value: "mix" },
			typeList: [
				{ text: "朵朵会员", value: "vip" },
				{ text: "AI 助手", value: "ai" },
				{ text: "会员+AI 助手", value: "mix" },
			],

			userData: {},
			vipText: "",
			vipList: [],
			selectIndex: 0,

			vipState: {
				list: [],
				defaultSelect: 0,
			},
			aiState: {
				list: [],
				defaultSelect: 0,
			},
			mixState: {
				list: [],
				defaultSelect: 0,
			},

			vipIntro: {
				rights: [],
				QA: [],
			},
			aiIntro: {
				rights: [],
				QA: [],
			},
			qa: [],
			rights: [],

			unionId: "",
			openid: "",

			orderNumber: "",

			btnDisable: false,
		};
	},
	computed: {},
	mounted() {
		this.$nextTick(async () => {
			let code = this.$route.query.code;
			let idInfo = await this.$user.getUnionIdWithAuth(code).catch(() => null);
			if (!idInfo) {
				this.$refs.modal.show("未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序");
				return;
			}
			if (idInfo.isSnapshotUser) {
				this.$refs.modal.show("请点击下方“使用完整服务”按钮后继续使用");
				return;
			}

			this.unionId = idInfo.unionId;
			this.openid = idInfo.openid;

			let userData = (
				await this.$http({
					url: "/vip/getUserDataForOfficialWithUnionId",
					data: {
						union_id: this.unionId,
					},
				})
			).user;

			if (!userData) {
				this.$refs.modal.show("未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序");
				return;
			}

			this.userData = userData;
			this.vipText = userData.vip_state > 0 ? this.$util.formatTime(userData.vip_expire_time, "date") + " 到期" : "您还不是会员";
			this.init();
			// this.$util.setJSSDKConfig(['chooseWXPay']);
		});
	},
	methods: {
		async init() {
			this.getVipIntro();
			await this.getAIPackageStateList();
			this.getVipList();
		},
		async getAIPackageStateList() {
			const res = await this.$http({
				url: "/aiagent/getAIPackageStateList",
			});
			const _mixState = { list: [], defaultSelect: res.defaultSelect };
			const _aiState = { list: [], defaultSelect: res.defaultSelect };
			res.list.forEach((item) => {
				if (item.vipLevel) {
					item.price = item.priceWithVip / 100;
					item.originalPrice = item.originalPriceWithVip ? item.originalPriceWithVip / 100 : 0;
					item.discount = item.originalPriceWithVip ? ((item.priceWithVip / item.originalPriceWithVip) * 10).toFixed(1) : 0;
					_mixState.list.push(item);
				} else {
					item.price = item.price / 100;
					item.originalPrice = item.originalPrice ? item.originalPrice / 100 : 0;
					item.discount = item.originalPrice ? ((item.price / item.originalPrice) * 10).toFixed(1) : 0;
					_aiState.list.push(item);
				}
			});
			this.aiState = _aiState;
			this.mixState = _mixState;
		},
		async getVipList() {
			let vipListResult = await this.$http({
				url: "/vip/getVipStateList",
				data: {
					platform: "official",
				},
			});
			vipListResult.list.forEach((item) => {
				item.price = item.price / 100;
				item.priceText = item.price.toFixed(2);
				if (item.originalPrice) {
					item.originalPrice = item.originalPrice / 100;
					item.originalPriceText = item.originalPrice.toFixed(2);
					item.discount = ((item.price / item.originalPrice) * 10).toFixed(1);
				}
			});
			this.vipState = vipListResult;
			this.onClickType(this.curType);
		},
		async getVipIntro() {
			let result = await this.$http({
				url: "/vip/getVipIntro",
			});
			this.vipIntro = {
				rights: result.rights,
				QA: result.QA.map((o) => ({ ...o, open: false })),
			};
			this.aiIntro = {
				rights: result.AIRights,
				QA: result.AIQA.map((o) => ({ ...o, open: false })),
			};
			this.refreshIntro();
		},
		refreshIntro() {
			const curType = this.curType;
			const rights = [];
			const QA = [];
			if (curType.value === "mix" || curType.value === "ai") {
				rights.push(...this.aiIntro.rights);
				QA.push(...this.aiIntro.QA);
			}
			if (curType.value === "mix" || curType.value === "vip") {
				rights.push(...this.vipIntro.rights);
				QA.push(...this.vipIntro.QA);
			}
			QA.forEach((i) => (i.open = false));
			this.rights = rights;
			this.qa = QA;
		},
		onClickType(t) {
			this.curType = t;
			this.vipList = this[`${t.value}State`].list;
			this.selectIndex = this[`${t.value}State`].defaultSelect;
			this.refreshIntro();
		},
		onVipItemClick(index) {
			this.selectIndex = index;
		},

		openQuestion(index) {
			this.qa[index].open = !this.qa[index].open;
		},

		openAllQuestion() {
			for (let i = 0; i < this.qa.length; i++) {
				this.qa[i].open = true;
			}
		},

		toQuestion() {
			let target = document.getElementById("more");
			window.scroll({
				top: target.offsetTop,
				left: 0,
				behavior: "smooth",
			});
		},

		async buyWrapper() {
			if (this.btnDisable) {
				return;
			}
			this.btnDisable = true;
			await this.buy();
			this.btnDisable = false;
		},

		async buy() {
			let selectVip = this.vipList[this.selectIndex];
			if (!selectVip) {
				this.$refs.modal.show("请选择会员时长");
				return;
			}

			this.$refs.loading.show();
			const curType = this.curType;
			const isAgent = curType.value !== "vip";
			const createBaseData = {
				union_id: this.unionId,
				official_openid: this.openid,
				from: "official",
				type: 1,
				level: selectVip.state,
			};
			isAgent && (createBaseData.with_vip = curType.value === "mix" ? 1 : 0);
			const createUrl = isAgent ? "/aiagent/createOrder" : "/vip/createOrder";
			const payIdUrl = isAgent ? "/aiagent/getPayId" : "/vip/getPayId";
			let res = await this.$http({
				url: createUrl,
				data: createBaseData,
			}).catch((err) => {
				this.$refs.modal.show(err);
				return null;
			});
			if (!res) {
				this.$refs.loading.hide();
				return;
			}

			this.orderNumber = res.orderNumber;
			if (res.state === 1) {
				this.$refs.loading.hide();
				return this.showSuccessModal();
			}

			let payIdRes = await this.$http({
				url: payIdUrl,
				data: {
					union_id: this.unionId,
					from: "official",
					order_number: this.orderNumber,
				},
			}).catch((err) => {
				this.$refs.modal.show(err);
				return null;
			});
			if (!payIdRes) {
				this.$refs.loading.hide();
				return;
			}

			wx.chooseWXPay({
				timestamp: payIdRes.timeStamp,
				nonceStr: payIdRes.nonceStr,
				package: payIdRes.package,
				signType: payIdRes.signType,
				paySign: payIdRes.paySign,
				success: (res) => {
					this.startCheckOrderState();
				},
				cancel: () => {
					this.$refs.loading.hide();
				},
				fail: () => {
					this.$refs.loading.hide();
				},
			});
		},

		startCheckOrderState() {
			if (!this.orderNumber) {
				this.$refs.loading.hide();
				return;
			}

			this.$refs.loading.show("结果查询中");
			const url = this.curType.value !== "vip" ? "/aiagent/checkPaySuccess" : "/vip/checkPaySuccess";
			this.timeId = setInterval(() => {
				this.$http({
					url,
					data: {
						union_id: this.unionId,
						from: "official",
						order_number: this.orderNumber,
					},
				}).then((res) => {
					if (res.pay_success) {
						clearInterval(this.timeId);
						this.$refs.loading.hide();
						this.showSuccessModal();
					}
				});
			}, 1000);
		},

		async showSuccessModal() {
			let selectVip = this.vipList[this.selectIndex];
			this.$refs.loading.hide();
			await this.$refs.modal.show(`开通成功！您的会员身份已生效${selectVip.state > 1 ? "或延长" : ""}，可尽享会员专属权益`);
			location.reload();
		},

		toGiveAway() {
			this.$router.push({
				name: "GiveAway",
			});
		},

		toBuyRecord() {
			this.$router.push({
				name: "BuyRecord",
			});
		},

		toServiceAgreement() {
			window.open("https://static.duoduo.link/Common_Service_Agreement.html");
		},
	},
};
</script>

<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.loading-text {
	font-size: pxToRem(40);
}

.header-wrapper {
	background-color: #37414b;
	padding: pxToRem(20) pxToRem(30) 0;
	box-sizing: border-box;
}

.header-card {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: pxToRem(150);
	background: #37414b linear-gradient(143deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
	box-shadow: 0 pxToRem(-2) pxToRem(4) pxToRem(2) rgba(0, 0, 0, 0.15);
	border-radius: pxToRem(20) pxToRem(20) 0 0;
	border: pxToRem(2) rgba(221, 177, 144, 0.2) solid;
	border-bottom: none;
	padding: 0 pxToRem(30);
	box-sizing: border-box;
}

.header-avatar {
	width: pxToRem(110);
	height: pxToRem(110);
	border-radius: 100%;
	border: pxToRem(2) solid #e5e5e5;
	display: block;
	box-sizing: border-box;
	margin-right: pxToRem(24);
	object-fit: cover;
}

.info-wrapper {
	flex: 1;
	font-size: 0;
}

.nickname-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.nickname {
	font-size: pxToRem(32);
	line-height: pxToRem(48);
	font-weight: bold;
	color: white;
}

.vip-badge {
	width: pxToRem(108);
	height: pxToRem(32);
	margin-left: pxToRem(12);
	display: block;
}

.vip-text {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 pxToRem(20);
	height: pxToRem(40);
	border-radius: pxToRem(40);
	background-color: rgba(0, 0, 0, 0.5);
	font-size: pxToRem(20);
	color: #d9d9d9;
	margin-top: pxToRem(6);
	&:last-child {
		margin-left: pxToRem(8);
	}
}

.number-id {
	position: absolute;
	top: pxToRem(8);
	right: pxToRem(20);
	font-size: pxToRem(20);
	color: #999999;
}

.premium-img {
	position: absolute;
	bottom: 0;
	right: pxToRem(12);
	width: pxToRem(189);
	height: pxToRem(80);
	display: block;
}

.content-container {
	background-color: #f7f7f7;
	padding: 0 pxToRem(30);
	box-sizing: border-box;
	overflow: auto;
}

.content-card {
	background-color: white;
	border-radius: pxToRem(20);
	box-shadow: 0 pxToRem(4) pxToRem(12) 0 rgba(0, 0, 0, 0.08);
	margin: pxToRem(20) auto;
}

.content-card.padding {
	padding: pxToRem(40) pxToRem(30);
	box-sizing: border-box;
}

.price-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: pxToRem(24);
}

.price-card {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: pxToRem(306);
	height: pxToRem(152);
	padding: 0 pxToRem(24);
	margin-bottom: pxToRem(18);
	background-color: #ffffff;
	border-radius: pxToRem(8);
	border: pxToRem(2) solid #bbbbbb;
	box-sizing: border-box;
}

.price-card.active {
	background-color: #f6ebe3;
	border-color: #ddb190;
}

.vip-name {
	font-size: pxToRem(28);
	color: #333333;
	font-weight: bold;
}

.price-text-wrapper {
	text-align: right;
}

.price {
	font-size: pxToRem(56);
	color: #fa5051;
	font-family: "DINAlternate-Bold";
}

.price-icon {
	font-size: pxToRem(24);
}

.price-sub-text {
	font-size: pxToRem(20);
	color: #777777;
}

.price-badge {
	position: absolute;
	top: pxToRem(-2);
	left: pxToRem(-2);
	padding: pxToRem(5) pxToRem(14);
	border-radius: pxToRem(8) 0 pxToRem(8) 0;
	font-size: pxToRem(22);
	color: white;
	z-index: 10;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: pxToRem(88);
	border-radius: pxToRem(80);
	font-size: pxToRem(30);
	font-weight: bold;
}

.button.primary {
	background: linear-gradient(135deg, #f7e9d2 0%, #ddb190 100%);
	color: #37414b;
}

.button.disable {
	background: #bbbbbb;
	color: #37414b;
}

.vip-tip-text {
	font-size: pxToRem(24);
	color: #999999;
	margin-bottom: pxToRem(40);
	text-align: center;
}

.link {
	color: #576b94;
	text-decoration: underline;
}

.link-btn-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: pxToRem(20);
}

.link-btn {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px #979797 solid;
	border-radius: pxToRem(66);
	height: pxToRem(66);
	box-sizing: border-box;
	margin-right: pxToRem(20);
	font-size: pxToRem(24);
	color: #37414b;
}

.link-btn:last-of-type {
	margin-right: 0;
}

.content-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: pxToRem(82);
	background-color: #37414b;
	border-radius: pxToRem(20) pxToRem(20) 0 0;
	padding: 0 pxToRem(30);
	box-sizing: border-box;
}

.head-main-text {
	font-size: pxToRem(28);
	color: white;
	font-weight: bold;
	background: linear-gradient(135deg, #f7e9d2 0%, #ddb190 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.head-sub-text {
	font-size: pxToRem(24);
	color: white;
}

.vip-rights-wrapper {
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	place-items: center;
	overflow: hidden;
}

.vip-rights {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	min-height: pxToRem(300);
	padding: pxToRem(30) pxToRem(60) 0;
	/* border-bottom: pxToRem(2) #dddddd solid; */
	box-sizing: border-box;
	grid-column-start: span 12;
	gap: pxToRem(2);
}
.vip-rights::after {
	content: "";
	position: absolute;
	inline-size: 100vw;
	block-size: pxToRem(1);
	inset-inline-start: 0;
	inset-block-start: pxToRem(-1);
	background-color: #ddd;
}
.vip-rights::before {
	content: "";
	position: absolute;
	inline-size: pxToRem(1);
	block-size: 100vh;
	background-color: #ddd;
	inset-inline-start: pxToRem(-1);
	inset-block-start: 0;
}

.rights-icon {
	width: pxToRem(108);
	height: pxToRem(108);
	display: block;
	border-radius: 100%;
}

.rights-name {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: pxToRem(26);
	font-weight: bold;
	line-height: pxToRem(40);
	color: #333333;
	margin-top: pxToRem(16);
}

.rights-arrow {
	width: pxToRem(12);
	height: pxToRem(18);
	display: block;
	margin-left: pxToRem(6);
}

.rights-desc {
	font-size: pxToRem(22);
	line-height: pxToRem(36);
	color: #777777;
	margin-top: pxToRem(4);
	text-align: center;
}

.more-text-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: pxToRem(30) 0;
	background-color: #37414b;
	border-radius: 0 0 pxToRem(20) pxToRem(20);
}

.more-icon {
	width: pxToRem(32);
	height: pxToRem(32);
	display: block;
	margin-right: pxToRem(8);
}

.more-text {
	font-size: pxToRem(26);
	color: white;
	font-weight: bold;
	background: linear-gradient(135deg, #f7e9d2 0%, #ddb190 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.vip-QA-wrapper {
}

.vip-QA {
}

.question {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: pxToRem(20) pxToRem(30);
	box-sizing: border-box;
	background-color: white;
	border-bottom: pxToRem(1) #eeeeee solid;
}

.question-text {
	font-size: pxToRem(26);
	color: #333333;
	font-weight: bold;
	flex: 1;
	margin-right: pxToRem(24);
}

.question-arrow {
	width: pxToRem(12);
	height: pxToRem(20);
	display: block;
	transform-origin: center center;
}

.question-arrow.open {
	transform: rotate(90deg);
}

.answer {
	padding: pxToRem(16) pxToRem(30) pxToRem(30);
	box-sizing: border-box;
	font-size: pxToRem(24);
	line-height: pxToRem(36);
	color: #777777;
	background-color: #eeeeee;
}

.bottom-buy-container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: white;
	box-shadow: 0 0 pxToRem(12) 0 rgba(0, 0, 0, 0.08);
}

.discount-badge {
	font-size: pxToRem(24);
	font-weight: bold;
	padding: pxToRem(4) pxToRem(10);
	color: white;
	background-color: #fa5051;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 0 pxToRem(2) pxToRem(2) pxToRem(2);
}

.bottom-padding {
	padding-bottom: env(safe-area-inset-bottom);
}

.bottom-buy-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 pxToRem(30);
	height: pxToRem(120);
}

.price-info {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin-top: pxToRem(22);
}

.bottom-text-1 {
	font-size: pxToRem(24);
	color: #333333;
	margin-bottom: pxToRem(8);
	font-weight: bold;
}

.bottom-price {
	font-size: pxToRem(56);
	color: #fa5051;
	font-family: "DINAlternate-Bold";
}

.bottom-original-price {
	font-size: pxToRem(20);
	color: #777777;
	text-decoration: line-through;
	margin-bottom: pxToRem(8);
	margin-left: pxToRem(16);
}

.bottom-button-wrapper {
	width: pxToRem(258);
}

.vip-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background: linear-gradient(135deg, rgba(255, 239, 207, 0.34) 0%, rgba(235, 183, 121, 0.34) 100%);
	border-radius: pxToRem(12);
	border: pxToRem(1) solid rgba(142, 92, 55, 0.26);
	color: #8e5c37;
	padding: pxToRem(24) pxToRem(30);
	margin: pxToRem(20) 0;
}

.vip-desc {
	font-size: pxToRem(26);
	font-weight: bold;
}

.vip-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 pxToRem(30);
	height: pxToRem(64);
	border-radius: pxToRem(56);
	background-color: #37414b;
	color: white;
	font-size: pxToRem(28);
	font-weight: bold;
	position: relative;
	z-index: 10;
}

.premium-img-small {
	position: absolute;
	bottom: 0;
	right: pxToRem(22);
	width: pxToRem(94);
	height: pxToRem(40);
	display: block;
}

.price-type {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: pxToRem(12);
	margin-top: pxToRem(20);
}
.price-type-item {
	--bg: #d9d9d9;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: pxToRem(80);
	font-size: pxToRem(30);
	font-weight: bold;
	border-radius: pxToRem(24) pxToRem(24) 0 0;
	background-color: var(--bg);
	color: #666666;
	@mixin triangle($position) {
		--s: pxToRem(24);
		content: "";
		position: absolute;
		bottom: 0;
		#{$position}: calc(-1 * pxToRem(24));
		width: pxToRem(24);
		height: pxToRem(24);
		@if ($position == "right") {
			background: radial-gradient(circle at pxToRem(24) 0, transparent pxToRem(24), var(--bg) pxToRem(24), var(--bg));
		} @else {
			background: radial-gradient(circle at 0 0, transparent pxToRem(24), var(--bg) pxToRem(24), var(--bg));
		}
	}
	&::before {
		@include triangle("left");
	}
	&::after {
		@include triangle("right");
	}
	&:first-child::before,
	&:last-child::after {
		visibility: hidden;
	}
	&.active {
		--bg: white;
		z-index: 1;
		color: #222;
	}
	.type-tag {
		position: absolute;
		width: pxToRem(65);
		height: pxToRem(36);
		right: 0;
		top: pxToRem(-14);
	}
}
</style>
