<template>
	<div>
		<div v-if="rechargePrices.length > 0" class="card-wrap DINAlternate-font">
			<div class="card-title-wrap">
				<div>充值嘿豆</div>
				<div class="card-title-info-wrap">
					<div class="assets-box">
						<BeanAmount
							size="38"
							:amount="heidouBalance.temporary + heidouBalance.permanent"
							fontSize="32"
							margin="14"
						></BeanAmount>
					</div>
				</div>
			</div>
			<div class="card-options-wrap">
				<div
					v-for="(item, index) in rechargePrices"
					:key="index"
					class="card-option"
					:class="{'card-option-with-vip-box': item.withVip, 'card-option-active': argIndex === index}"
					@click.stop="onClickCardOption(index)"
				>
					<div v-if="item.badge.text" class="card-option-badge orange-back">{{item.badge.text}}</div>
					<div :class="{'card-option-with-vip': item.withVip}">
						<div class="card-option-item-wrap">
							<span>{{item.heidouAmount}}</span>
							<img src="../assets/bean.png" />
						</div>
						<div class="card-option-price-wrap" :class="{'orange-back': item.highlight}">
							<span><span class="yuan-icon">￥</span>{{item.price / 100}}</span>
						</div>
					</div>
					<div v-if="item.withVip" class="card-option-with-vip-box-right">
						<div>
							<img src="../assets/zeng.png" />
							<span>365天朵朵会员</span>
							<span class="card-option-with-vip-box-right-desc">价值￥119.99</span>
						</div>
						<div>
							<div class="card-option-with-vip-box-right-bottom-item">
								<img src="../assets/xiang.png" />
								<div class="card-option-with-vip-box-right-bottom-item-right">
									<div>8<span> 折</span></div>
									<div class="card-option-with-vip-box-right-desc" style="font-size: 0.2666rem;">嘿豆充值优惠</div>
								</div>
							</div>
							<div class="card-option-with-vip-box-right-bottom-item">
								<img src="../assets/de.png" />
								<div class="card-option-with-vip-box-right-bottom-item-right">
									<div>300<span> 枚</span></div>
									<div class="card-option-with-vip-box-right-desc" style="font-size: 0.2666rem;">每月额外嘿豆</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-bottom-wrap">
				<div>
					<span @click.stop="toHeidouHistory">充值记录</span>
					<span @click.stop="toServiceAgreement">《增值服务协议》</span>
				</div>
			</div>
			<div style="margin-top: 0.6rem;" @click.stop="onClickRecharge">
				<HeyButton>
					<div style="font-size: 0.4rem; font-weight: bold; color: white">
						<span>支付 ￥{{rechargePrices[argIndex].price / 100}}</span>
					</div>
				</HeyButton>
			</div>
		</div>

		<loading ref="loading"></loading>

		<modal ref="modal"></modal>
	</div>
</template>

<script>
import BeanAmount from '../components/BeanAmount';
import HeyButton from '../components/HeyButton';
import Loading from '../components/Loading';
import Modal from '../components/Modal';
export default {
	name: 'BuyHeidou',
	components: { Modal, Loading, HeyButton, BeanAmount },
	data() {
		return {
			heidouBalance: {
				temporary: 0,
				permanent: 0,
			},
			rechargePrices: [],
			argIndex: 3,

			orderNumber: '',

			unionId: '',
			openid: '',
			userData: {},

			btnDisable: false,
		};
	},
	async mounted() {
		let code = this.$route.query.code;
		let idInfo = await this.$user.getUnionIdWithAuth(code);
		if (!idInfo) {
			this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
			return;
		}
    if (idInfo.isSnapshotUser) {
      this.$refs.modal.show('请点击下方“使用完整服务”按钮后继续使用');
      return;
    }

		this.unionId = idInfo.unionId;
		this.openid = idInfo.openid;

		let userData = (await this.$http({
			url: '/vip/getUserDataForOfficialWithUnionId',
			data: {
				union_id: this.unionId,
			},
		})).user;

		if (!userData) {
			this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
			return;
		}

		this.userData = userData;
		this.initSocialInfo();
		this.getPriceList();
	},
	methods: {
		getPriceList() {
			this.$http({
				url: '/social/getPriceList',
				data: {
					union_id: this.unionId,
				},
			}).then(res => {
				this.rechargePrices = res;
			})
		},
		initSocialInfo() {
			this.$http({
				url: '/social/userInfo',
				data: {
					union_id: this.unionId,
				},
			}).then(res => {
				this.heidouBalance = res.heidouBalance;
			})
		},
		onClickCardOption(index) {
			this.argIndex = index;
		},
		async buyWrapper() {
			if (this.btnDisable) {
				return;
			}
			this.btnDisable = true;
			await this.onClickRecharge();
			this.btnDisable = false;
		},
		async onClickRecharge() {
			let item = this.rechargePrices[this.argIndex];
			if (!item) {
				this.$refs.modal.show('请选择充值套餐');
				return;
			}

			this.$refs.loading.show();

			let orderRes = await this.$http({
				url: '/social/createRechargeOrder',
				data: {
					union_id: this.unionId,
					official_openid: this.openid,
					from: 'official',
					price_id: item.id,
				},
			}).catch(err => {
				this.$refs.modal.show(err);
				return null;
			});
			if (!orderRes) {
				this.$refs.loading.hide();
				return;
			}

			this.orderNumber = orderRes.orderNumber;
			if (orderRes.state === 1) {
				this.$refs.loading.hide();
				return this.showSuccessModal();
			}

			wx.chooseWXPay({
				timestamp: orderRes.timeStamp,
				nonceStr: orderRes.nonceStr,
				package: orderRes.package,
				signType: orderRes.signType,
				paySign: orderRes.paySign,
				success: (res) => {
					this.startCheckOrderState();
				},
				cancel: () => {
					this.$refs.loading.hide();
				},
				fail: () => {
					this.$refs.loading.hide();
				},
			});
		},
		startCheckOrderState() {
			if (!this.orderNumber) {
				this.$refs.loading.hide();
				return;
			}

			this.$refs.loading.show('结果查询中');

			this.timeId = setInterval(() => {
				this.$http({
					url: '/social/checkPaySuccess',
					data: {
						union_id: this.unionId,
						from: 'official',
						order_number: this.orderNumber,
					},
				}).then(res => {
					if (res.pay_success) {
						clearInterval(this.timeId);
						this.$refs.loading.hide();
						this.showSuccessModal();
						this.heidouBalance = res.heidouBalance;
					}
				});
			}, 1000);
		},
		async showSuccessModal() {
			this.$refs.loading.hide();
			await this.$refs.modal.show('充值成功');
		},
		toHeidouHistory() {
			this.$router.push({
				name: 'HeidouHistory'
			});
		},
		toServiceAgreement() {
			location.href = 'https://static.duoduo.link/Common_Service_Agreement.html';
		},
	}
};
</script>

<style lang="scss" src="../style/heyCommon.scss" />
<style lang="scss" src="../style/heyCard.scss" />
<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.card-options-wrap .card-option .card-option-price-wrap {
	color: white;
}
.card-options-wrap .card-option .card-option-price-wrap text:before {
	content: '￥';
	font-size: 0.6em;
}
.card-wrap .card-options-wrap .card-option-with-vip-box {
	/*display: flex;*/
	width: 100%;
	margin: 0;
	flex-direction: row;
	/*height: pxToRem(270);*/
	/*box-sizing: border-box;*/
	/*border-radius: pxToRem(30);*/
	/*border: 2px solid rgba(255, 255, 255, 0.7);*/
}
.card-wrap .card-options-wrap .card-option-with-vip-box .card-option-with-vip {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: pxToRem(230);
	height: 100%;
}
.card-option-with-vip-box-right {
	flex: 1;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: pxToRem(33);
	margin-top: pxToRem(30);
}
.card-option-with-vip-box-right > div {
	display: flex;
	align-items: center;
	font-size: pxToRem(20);
}
.card-option-with-vip-box-right .card-option-with-vip-box-right-desc {
	opacity: .7;
	font-size: pxToRem(24);
}
.card-option-with-vip-box-right div:nth-child(1) img {
	width: pxToRem(50);
	height: pxToRem(50);
	margin-right: pxToRem(12);
}
.card-option-with-vip-box-right div:nth-child(1) span:first-of-type {
	font-size: pxToRem(28);
	font-weight: bold;
	margin-right: pxToRem(4);
}
.card-option-with-vip-box-right-bottom-item {
	display: flex;
	align-items: center;
	margin-top: pxToRem(30);
}
.card-option-with-vip-box-right-bottom-item:nth-child(1) {
	margin-right: pxToRem(30);
}
.card-option-with-vip-box-right-bottom-item img {
	width: pxToRem(44);
	height: pxToRem(44);
	margin-right: pxToRem(12);
}
.card-option-with-vip-box-right-bottom-item-right {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.card-option-with-vip-box-right-bottom-item-right div:nth-child(1) {
	font-size: pxToRem(30);
	line-height: pxToRem(30);
}
.card-option-with-vip-box-right-bottom-item-right div:nth-child(1) text {
	font-size: pxToRem(20) !important;
}

</style>
