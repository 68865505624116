<template>
	<div>
		<div class="record-wrap">
			<div class="content-card" style="padding: 0 0.533rem;">
				<div v-for="item in dataList" :key="item.id" class="record-item flex-space-between">
					<div class="detail-info">
						<div class="detail">{{item.detail}}</div>
						<div class="time">{{item.create_time}}</div>
					</div>
					<BeanAmount
						size="44"
						:amount="(item.amount > 0 ? '+' : '') + item.amount"
						fontSize="36"
						margin="16"
					></BeanAmount>
				</div>
			</div>
		</div>
		<div class="bottom-text">
			<block v-if="loading">加载中</block>
			<block v-if="!loading && dataList.length > 0">已加载全部</block>
			<block v-if="!loading && dataList.length === 0">暂无记录</block>
		</div>
	</div>
</template>

<script>
import BeanAmount from '../components/BeanAmount';
export default {
	name: 'HeidouHistory',
	components: { BeanAmount },
	data() {
		return {
			dataList: [],

			page: 1,
			limit: 999,
			timestamp: 0,
			isEnd: false,
			loading: false,

			unionId: '',
			openid: '',
			userData: {},
		};
	},
	async mounted() {
		let code = this.$route.query.code;
		let idInfo = await this.$user.getUnionIdWithAuth(code);
		if (!idInfo) {
			this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
			return;
		}
    if (idInfo.isSnapshotUser) {
      this.$refs.modal.show('请点击下方“使用完整服务”按钮后继续使用');
      return;
    }

		this.unionId = idInfo.unionId;
		this.openid = idInfo.openid;

		let userData = (await this.$http({
			url: '/vip/getUserDataForOfficialWithUnionId',
			data: {
				union_id: this.unionId,
			},
		})).user;

		if (!userData) {
			this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
			return;
		}

		this.requestData();
	},
	methods: {
		requestData() {
			if (this.loading || this.isEnd) return;
			this.loading = true;
			this.$http({
				url: '/social/getRechargeHistory',
				data: {
					union_id: this.unionId,
					page: this.page,
					limit: this.limit,
					timestamp: this.timestamp,
				},
			}).then(res => {
				this.dataList = this.dataList.concat(res.entry);
				this.loading = false;
				this.isEnd = res.isEnd;
				this.timestamp = res.timestamp;
			});
		},
	},
};
</script>

<style lang="scss" src="../style/heyCommon.scss" />
<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.record-wrap {
	padding: pxToRem(30);
}

.record-item {
	padding: pxToRem(30) 0;
	border-bottom: pxToRem(2) solid rgba(255, 255, 255, 0.1);
}

.record-item:last-of-type {
	border-bottom: none;
}

.detail-info .detail {
	font-size: pxToRem(30);
	color: white;
	margin-bottom: pxToRem(8);
	font-weight: bold;
}

.time {
	font-size: pxToRem(24);
	color: rgba(255, 255, 255, .7);
}

.bottom-text {
	padding: pxToRem(50);
	text-align: center;
	font-size: pxToRem(26);
	color: #777777;
}

.flex-space-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
