<template>
	<div>
		<div v-if="!unionId" class="load-mask"></div>

		<div class="record division-after division-after-none-last" v-for="(item, index) in recordList" :key="index">
			<div class="record-title-wrapper">
				<div class="record-title">{{item.title}}</div>
				<div class="record-time">{{item.create_time}}</div>
			</div>
			<div class="record-detail" v-if="item.detail">{{item.detail}}</div>
		</div>

		<div class="bottom-text">
			<block v-if="!requestFinish">加载中</block>
			<block v-if="requestFinish && recordList.length > 0">已加载全部</block>
			<block v-if="requestFinish && recordList.length === 0">暂无记录</block>
		</div>

		<!--下面这个用来给页面底部撑起来一些冗余-->
		<div style="width: 100%; height: 100px;"></div>

		<loading ref="loading"></loading>

		<modal ref="modal"></modal>
	</div>
</template>

<script>
import Loading from "../components/Loading";
import Modal from "../components/Modal";
export default {
	name: "BuyRecord",
	components: { Modal, Loading },
	data() {
		return {
			recordList: [],
			requestFinish: false,

			unionId: '',
			openid: '',
		}
	},
	async mounted() {
		let code = this.$route.query.code;
		this.unionId = this.$user.getLocalUnionId();
		this.openid = this.$user.getLocalOpenid();
		if (!code && (!this.unionId || !this.openid)) {
			this.$user.toAuthPage();
			return;
		}

		let userData = null;
		if (code && !this.unionId) {
			let result = await this.$http({
				url: '/vip/getUserDataForOfficial',
				data: {
					code,
				}
			});
			if (!result || !result.user) {
				this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
				return;
			}
			this.unionId = result.officialUserdata.unionid;
			this.openid = result.officialUserdata.openid;
			this.$user.setLocalOpenid(result.officialUserdata.openid);
			this.$user.setLocalUnionId(result.officialUserdata.unionid);
			userData = result.user;
		}

		if (!userData) {
			userData = (await this.$http({
				url: '/vip/getUserDataForOfficialWithUnionId',
				data: {
					union_id: this.unionId,
				},
			})).user;
		}

		if (!userData) {
			this.$refs.modal.show('未找到匹配的朵朵校友圈账号，请先登录“朵朵校友圈”小程序');
			return;
		}

		this.recordList = await this.$http({
			url: '/vip/getOrderList',
			data: {
				union_id: this.unionId,
			},
		}).catch(err => {
			this.$refs.modal.show(err);
			return [];
		});
		this.requestFinish = true;
	}
}
</script>

<style lang="scss" scoped>
$uiWidth: 750;
@function pxToRem($px) {
	@return 10 * $px/$uiWidth + rem;
}

.record {
	padding: pxToRem(30);
	box-sizing: border-box;
	background-color: white;
}

.record-title-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.record-title {
	font-size: pxToRem(30);
	font-weight: bold;
	color: #333333;
}

.record-time {
	font-size: pxToRem(22);
	color: #777777;
}

.record-detail {
	margin-top: pxToRem(12);
	font-size: pxToRem(26);
	color: #777777;
}

.bottom-text {
	padding: pxToRem(50);
	text-align: center;
	font-size: pxToRem(26);
	color: #777777;
}
</style>
