import constants from "./constants";
import http from './http';

export default {
	setLocalUnionId: function(unionId) {
		localStorage.unionId = unionId;
	},

	getLocalUnionId: function() {
		let unionId = localStorage.unionId || '';
		return unionId;
	},

	setLocalOpenid: function(openid) {
		localStorage.officialOpenid = openid;
	},

	getLocalOpenid: function() {
		let openid = localStorage.officialOpenid || '';
		return openid;
	},

	setUserData: function(userData) {
		localStorage.userData = JSON.stringify(userData);
	},

	getUserData: function() {
		return JSON.parse(localStorage.userData || '{}');
	},

	toAuthPage: function() {
		window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${constants.appId}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
	},

	getUnionIdWithAuth: async function (code) {
		const hasUpdateNewOpenid = localStorage.hasUpdateNewOpenid;
		if (!hasUpdateNewOpenid) {
			this.setLocalUnionId('');
			this.setLocalOpenid('');
			localStorage.hasUpdateNewOpenid = 1;
		}

		let unionId = this.getLocalUnionId();
		let openid = this.getLocalOpenid();

		// 如果两个都有，则直接返回
		if (unionId && openid) {
			return { unionId, openid };
		}

		// 如果有缺失，且没有 code，则先去授权
		if (!code) {
			this.toAuthPage();
			return null;
		}

		// 如果有缺失，且有 code，则获取 unionId 和 openid
		let result = await http({
			url: '/vip/getUserDataForOfficial',
			data: {
				code,
			}
		});
		if (result && result.officialUserdata && result.officialUserdata.isSnapshotUser) {
			return {
				isSnapshotUser: result.officialUserdata.isSnapshotUser,
			}
		}
		if (!result || !result.user) {
			return null;
		}
		unionId = result.officialUserdata.unionid;
		openid = result.officialUserdata.openid;
		this.setLocalOpenid(result.officialUserdata.openid);
		this.setLocalUnionId(result.officialUserdata.unionid);
		return { unionId, openid };
	},
}
